import { Injectable } from '@angular/core';
import { Device } from '@capacitor/device';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';

import { Observable, Subject } from 'rxjs';
import packageJson from '../../../../../package.json';
import { AlunoApiService } from '../../http/aluno/aluno-api.service';
import { AppNotification } from '../../http/notification/notification.interface';
import { OpenedPush } from '../../http/push/push-api.interface';
import { PushApiService } from '../../http/push/push-api.service';
import { PutAlunoDeviceBody } from '../aluno/aluno.interface';
import { PlatformService } from '../platform/platform.service';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationsService {
  private notificacoesSubject: Subject<AppNotification> = new Subject<AppNotification>();
  public notificacoes$: Observable<AppNotification> = this.notificacoesSubject.asObservable();
  private hasInit = false;

  constructor(
    private alunoApi: AlunoApiService,
    private pushApi: PushApiService,
    private platformService: PlatformService
  ) {}

  async init() {
    if (this.hasInit) {
      return;
    }

    this.hasInit = true;

    if (this.platformService.isNative()) {
      await PushNotifications.register();
      PushNotifications.requestPermissions();
      PushNotifications.addListener('registration', (token: Token) => {
        this.onTokenRegistration(token);
      });

      PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
        this.onNotificationReceived(notification);
      });

      PushNotifications.addListener('pushNotificationActionPerformed', (notificationAction: ActionPerformed) => {
        this.onNotificationTapped(notificationAction);
      });
    }
  }

  onTokenRegistration(token: Token) {
    this.postTokenApi(token.value);
  }

  /*
    Action to be performed when the user receives notifications
    with the app opened.
  */
  onNotificationReceived(notification: PushNotificationSchema) {
    const foreground = true;
    this.notificacoesSubject.next(this.parseNotification(notification, foreground));
    this.putPushOpened({ foreground }, +notification.id);
  }

  /*
    Action to be performed when the user taps the push notification
    with the app closed or on background
  */
  onNotificationTapped(notificationAction: ActionPerformed) {
    const foreground = false;
    this.notificacoesSubject.next(this.parseNotification(notificationAction.notification, foreground));
    this.putPushOpened({ foreground }, +notificationAction.notification.id);
  }

  private parseNotification(pushNotification: PushNotificationSchema, isForeground: boolean) {
    const appNotification = {
      source: '',
      sourceIds: [] as string[],
      redirectRoute: [] as string[],
      type: 'push',
      title: pushNotification.title,
      trigger: isForeground ? 'foreground' : 'background',
    };

    switch (pushNotification.data.tipo) {
      // Só um evento (criado, editado ou apagado)
      case 'evento':
      case 'evento-editado':
      case 'evento-apagado':
        appNotification.source = 'evento';
        // Se tem apenas um evento, tipos_ids conterá o respectivo id
        appNotification.sourceIds = [pushNotification.data.tipo_ids];
        appNotification.redirectRoute = ['/evento', pushNotification.data.tipo_ids];
        break;
      // Mais de um evento
      case 'eventos':
        appNotification.source = 'evento';
        appNotification.sourceIds = pushNotification.data.tipo_ids.split(',');
        appNotification.redirectRoute = ['/eventos'];
        break;
      default:
        appNotification.source = pushNotification.data.tipo;
    }

    return appNotification as AppNotification;
  }

  private putPushOpened(openedPush: OpenedPush, pushId: number) {
    return this.pushApi.putPush(openedPush, pushId);
  }

  // Atualiza device do aluno com o token
  private async postTokenApi(token: string) {
    const device = await Device.getInfo();
    const deviceId = await Device.getId();

    const payload: PutAlunoDeviceBody = {
      push_token: token,
      name: device.name ?? null,
      platform: device.platform,
      operating_system: device.operatingSystem,
      os_version: device.osVersion,
      manufacturer: device.manufacturer || 'unknown',
      model: device.model,
      web_view_version: device.webViewVersion ?? null,
      app_version: packageJson.version,
      is_virtual: device.isVirtual,
    };

    return this.alunoApi.putAlunoDevice(deviceId.identifier, payload);
  }
}
