import { Injectable } from '@angular/core';

import { filter, from, of, Subject, zip, forkJoin, Observable } from 'rxjs';
import { map, mergeMap, switchMap, take, tap } from 'rxjs/operators';

import packageJson from '../../../../../package.json';

import { AlunoApiService } from '../../http/aluno/aluno-api.service';

import {
  GetAlunoMatrizApiResponse,
  HistoricoItem,
  Turma,
  GradeHorariaTurno,
} from '../../http/aluno/aluno-api.interface';
import { GradeHorariaAula, GradeHorariaDia, PutAlunoDeviceBody } from './aluno.interface';

import { DisciplinaStore } from '../../../modules/historicos/state/disciplina.store';
import { GradeStore } from '../../../modules/historicos/state/grade.store';

import { AppSettings } from '../../../app.settings';
import { AlunoQuery } from '../../../modules/aluno/state/aluno.query';
import { AlunoStore } from '../../../modules/aluno/state/aluno.store';
import { DisciplinaQuery } from '../../../modules/historicos/state/disciplina.query';
import { GradeQuery } from '../../../modules/historicos/state/grade.query';
import { GradeGridStore } from '../../../modules/historicos/state/grade-grid.store';
import { GradeGridQuery } from '../../../modules/historicos/state/grade-grid.query';
import SortUtilities from '../../../shared/utilities/sort.utilities';
import { LocalNotificationsService } from '../local-notifications/local-notifications.service';

import { Device } from '@capacitor/device';
import { MatrizStore } from '../../../modules/historicos/state/matriz.store';
import { InstagramAPIService } from '../../http/instagram/instagram-api.service';

@Injectable({
  providedIn: 'root',
})
export class AlunoService {
  isLoading = new Subject<boolean>();

  constructor(
    private alunoApi: AlunoApiService,
    private instagramApi: InstagramAPIService,
    private alunoStore: AlunoStore,
    private alunoQuery: AlunoQuery,
    private disciplinaStore: DisciplinaStore,
    private disciplinaQuery: DisciplinaQuery,
    private matrizStore: MatrizStore,
    private gradeStore: GradeStore,
    private gradeQuery: GradeQuery,
    private gradeGridStore: GradeGridStore,
    private gradeGridQuery: GradeGridQuery,
    private localNotifications: LocalNotificationsService
  ) {}

  toggleCursoAtivo(ativo: boolean, curso: GetAlunoMatrizApiResponse) {
    // atualiza quais cursos estão ativos no app
    const cursos = this.alunoQuery.getValue().cursos.filter((c) => c.matriz_id !== curso.matriz_id);
    cursos.push({ ...curso, app_ativo: ativo });
    this.alunoStore.update({ cursos });

    const cursoSelecionado = this.alunoQuery.idMatrizSelecionada();
    if (!ativo && cursoSelecionado === curso.matriz_id) {
      const primeiroCursoAtivo = cursos.find((el) => el.app_ativo);
      if (!!primeiroCursoAtivo) {
        this.alunoStore.update({ idMatrizSelecionada: primeiroCursoAtivo.matriz_id });
      }
    }
  }

  carregarDadosAluno() {
    return zip(this.carregarAluno(), this.carregarCursos(), this.enviarInformacoesDevice());
  }

  carregarHistoricoCompleto() {
    this.disciplinaStore.setLoading(true);

    const idMatrizSelecionada = this.alunoQuery.getValue().idMatrizSelecionada;
    if (!idMatrizSelecionada) {
      return of<HistoricoItem[]>([]);
    }

    return this.alunoApi.getAlunoCursoHistoricoCompleto(idMatrizSelecionada).pipe(
      tap((historicos) => {
        this.disciplinaStore.set(historicos);
        this.disciplinaStore.setLoading(false);
      })
    );
  }

  loadHistoricoAndGrade() {
    const requestsArr: Array<Observable<HistoricoItem[]> | Observable<GradeHorariaTurno[]>> = [
      this.carregarHistoricoAtual(),
    ];

    const shouldDisplayGrade = this.gradeGridQuery.isFuncionalidadeActive();
    if (shouldDisplayGrade) {
      requestsArr.push(this.loadGradeHoraria());
    }

    return forkJoin(requestsArr);
  }

  carregarHistoricoAtual() {
    if (!this.gradeQuery.getHasCache()) {
      this.gradeStore.setLoading(true);
    }

    const idMatrizSelecionada = this.alunoQuery.getValue().idMatrizSelecionada;
    if (!idMatrizSelecionada) {
      return of<HistoricoItem[]>([]);
    }

    return this.alunoApi.getAlunoCursoHistoricoAtual(idMatrizSelecionada).pipe(
      tap((historicos) => {
        historicos.forEach((historico, index) => {
          historico.cor = AppSettings.CORES_DISCIPLINAS[index];
        });

        this.gradeStore.set(historicos);
        this.gradeStore.setLoading(false);

        // Cria notificacoes pras aulas
        this.localNotifications.criarNotificacoes();
      })
    );
  }

  loadGradeHoraria() {
    const idMatrizSelecionada = this.alunoQuery.getValue().idMatrizSelecionada;
    if (!idMatrizSelecionada) {
      return of<GradeHorariaTurno[]>([]);
    }
    return this.alunoApi.getAlunoGradeHoraria(idMatrizSelecionada).pipe(
      tap((gradeHoraria) => {
        this.gradeGridStore.update({ gradeHoraria });
      })
    );
  }

  updateShowTooltip(showTooltip: boolean) {
    this.gradeGridStore.update({ showTooltip });
  }

  carregarSituacoesDisciplinaMatriz() {
    this.matrizStore.setLoading(true);

    return this.alunoQuery.cursoAtual$.pipe(
      filter((curso) => !!curso),
      mergeMap((curso) => {
        return this.alunoApi.getAlunoCursoMatrizSituacoes(curso!.matriz_id).pipe(
          tap((situacoes) => {
            this.matrizStore.set(situacoes);
            this.matrizStore.setLoading(false);
          })
        );
      })
    );
  }

  getHistorico(id: number): HistoricoItem | undefined {
    return this.disciplinaQuery.getEntity(id);
  }

  getAlunosTurma(turmaId: Turma['turma_id']) {
    // Propositalmente nao armazenando na store
    return this.alunoApi.getAlunosTurma(turmaId);
  }

  /**
   * Return a File object converted from a Base64 image
   *
   * @param imagem Base64 image
   * @param filename Name for the file
   *
   * @returns File object
   */
  convertBase64ImageToFile(imagem: string, filename: string) {
    const decodedImage = atob(imagem);
    const arrayImage = new Uint8Array(decodedImage.length);
    for (let i = 0; i < decodedImage.length; i++) {
      arrayImage[i] = decodedImage.charCodeAt(i);
    }

    return new File([arrayImage], filename, { type: 'image/png' });
  }

  enviarFoto(imagem: string) {
    return this.alunoApi.postAlunoFoto(this.convertBase64ImageToFile(imagem, 'foto_perfil'));
  }

  conectarInstagram(authCode: string) {
    const handle = async (code: string) => {
      const token = await this.alunoApi.postAlunoAuthCodeInstagram({ code }).toPromise();
      if (!token) {
        return;
      }
      const me = await this.instagramApi.getMe(token.access_token).toPromise();
      if (!me) {
        return;
      }
      return this.alunoApi
        .postAlunoPerfilInstagram({
          username: me.username,
        })
        .toPromise();
    };
    return from(handle(authCode));
  }

  desconectarInstagram() {
    return this.alunoApi.deleteAlunoPerfilInstagram();
  }

  carregarAluno() {
    return this.alunoApi.getAluno().pipe(tap((aluno) => this.alunoStore.update({ aluno })));
  }

  atualizarFaltas(historico: HistoricoItem) {
    const atualizaEntidade = () => {
      return {
        faltas_manuais: historico.faltas_manuais,
        maximo_faltas_manuais: historico.maximo_faltas_manuais,
      };
    };

    return this.alunoApi.patchHistorico(historico).pipe(
      tap(() => {
        this.gradeStore.update(historico.historico_id, atualizaEntidade);
        this.disciplinaStore.update(historico.historico_id, atualizaEntidade);
      })
    );
  }

  separarDisciplinsaPorDia(historico: HistoricoItem[]) {
    let gradeHoraria = this.getGradeHorariaVazia();

    for (const itemHistorico of historico) {
      const horariosDisciplina = itemHistorico.turma.horarios;

      if (!horariosDisciplina) {
        continue;
      }

      // Itera entre todos os horarios da disciplina e popula o objeto da grade
      // dependendo do dia da semana
      for (const horario of horariosDisciplina) {
        const diaDaGrade = gradeHoraria[horario.dia_da_semana];
        const horarioHistorico = { ...itemHistorico, horario };
        diaDaGrade.aulas = this.addHistoricoHorarioAoDia(horarioHistorico, diaDaGrade);
      }
    }

    // Remove domingo da grade horaria
    gradeHoraria = gradeHoraria.filter((dia) => dia.label !== 'Domingo');

    // Ordena as aulas dependendo do horario de inicio
    for (const dia of gradeHoraria) {
      dia.aulas.sort((a, b) => SortUtilities.sortByTime(a.horario.hora_inicio, b.horario.hora_inicio));

      // console.log(dia.aulas);
    }

    return gradeHoraria;
  }

  enviarInformacoesDevice() {
    return zip(from(Device.getId()), this.loadInfoFromDevicePlugin()).pipe(
      take(1),
      switchMap(([deviceId, deviceInfo]) => this.alunoApi.putAlunoDevice(deviceId.identifier, deviceInfo))
    );
  }

  /**
   * Load information about the device from the plugin
   */
  public loadInfoFromDevicePlugin(): Observable<PutAlunoDeviceBody> {
    return from(Device.getInfo()).pipe(
      map((device) => {
        const deviceInfo: PutAlunoDeviceBody = {
          name: device.name ?? null,
          platform: device.platform,
          operating_system: device.operatingSystem,
          os_version: device.osVersion,
          manufacturer: device.manufacturer || 'unknown',
          model: device.model,
          web_view_version: device.webViewVersion ?? null,
          app_version: packageJson.version,
          is_virtual: device.isVirtual,
          push_token: null,
        };

        return deviceInfo;
      })
    );
  }

  private carregarCursos() {
    return this.alunoApi.getAlunoCursos().pipe(
      map((cursos) => {
        const cursosStore = this.alunoQuery.getValue().cursos;
        const storeVazia = this.alunoQuery.getValue().cursos.length < 1;
        return cursos.map((c) => {
          const curso = cursosStore.find((t) => c.codigo_id === t.codigo_id);
          if (curso) {
            c.app_ativo = curso.app_ativo;
          } else {
            c.app_ativo = storeVazia;
          }
          return c;
        });
      }),
      tap((cursos) => {
        // Seleciona o primeiro curso por padrão;
        if (cursos && cursos.length > 0) {
          const matrizSelecionadaAtual = this.alunoQuery.getValue().idMatrizSelecionada;
          const idMatrizSelecionada = matrizSelecionadaAtual ?? cursos[0].matriz_id;
          this.alunoStore.update({ cursos, idMatrizSelecionada });
        }
      })
    );
  }

  private getGradeHorariaVazia() {
    const diasDaSemana = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];

    const gradeHoraria: GradeHorariaDia[] = diasDaSemana.map((dia, index) => {
      return {
        label: dia,
        dia_da_semana: index,
        aulas: [],
      };
    });

    return gradeHoraria;
  }

  private addHistoricoHorarioAoDia(historico: GradeHorariaAula, grade: GradeHorariaDia) {
    const historicos = [...grade.aulas, historico];
    return historicos;
  }
}
