// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
export const environment = {
  production: false,
  api: 'https://api.utfapp.com',
  rollbar: {
    accessToken: '232510c5a30b4d16b4de327d7b9d6bd2',
    environment: 'development',
    enabled: true,
  },
  instagram: {
    base_url: '',
    graph_url: '',
    oauth_url: '',
  },
  loginPeriodForReview: 5,
};
