import { Injectable } from '@angular/core';
import { InAppReview } from '@capacitor-community/in-app-review';

@Injectable({
  providedIn: 'root',
})
export class AppRatingService {
  pedirAvaliacao() {
    InAppReview.requestReview();
  }
}
